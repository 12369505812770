<template>
  
  <div id="redoc-container"></div>

</template>
  
<script>
  import apidoc from '../../public/kit4planning-apidoc-ws.json'
  import * as redoc from 'redoc/bundles/redoc.standalone'

  export default {

    name: 'Redoc',

    data() {
      return {
        apidoc,
        redocOptions: {
          hideDownloadButton: true,
        }
      }
    },

    mounted() {
      redoc.init(apidoc, this.redocOptions, document.getElementById('redoc-container'))
    }

  }
</script>