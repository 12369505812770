import { createApp } from 'vue'
import App from './App.vue'
import router from './router/routes.js'
import "bootstrap/dist/js/bootstrap.min.js"
import "@mdi/font/css/materialdesignicons.min.css"
import "./assets/css/main.css";

createApp(App)
    .use(router)
    .mount('#app')
